import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, AuthContext } from './context/AuthContext';
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import MembersPage from './pages/MembersPage';
import MemberForm from './pages/MemberForm';
import MemberDetails from './pages/MembersDetails';
import MatchesPage from './pages/MatchesPage';
import MatchForm from './pages/MatchForm';
import MatchDetails from './pages/MatchesDetails';
import AddMembersToMatch from './pages/AddMembersToMatch';
import UserDetails from './pages/UserDetails';
import NotFoundPage from './pages/NotFoundPage';
import AddChild from './pages/AddChild';


import { ThemeProvider, createTheme } from '@mui/material/styles'; // Import necessary components
import CssBaseline from '@mui/material/CssBaseline'; // Optional: for consistent styling across browsers

import './styles.css';
import RegistrationForm from './pages/RegistrationForm';

const theme = createTheme({
  // You can customize your theme here
});

const PrivateRoute = ({ element, roles }) => {
  const { user } = React.useContext(AuthContext);
  if (!user) {
    return <Navigate to="/login" />;
  }
  if (roles && roles.indexOf(user.role) === -1) {
    return <Navigate to="/" />;
  }
  return element;
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Optional: Resets CSS to ensure consistent styling */}
      <Router>
        <AuthProvider>
          <Layout>
            <Routes>
              <Route path="/" element={<PrivateRoute element={<HomePage />} />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegistrationForm />} />
              <Route path="/members" element={<PrivateRoute element={<MembersPage />} roles={['admin']} />} />
              <Route path="/members/new" element={<PrivateRoute element={<MemberForm />} roles={['admin']} />} />
              <Route path="/members/:id" element={<PrivateRoute element={<MemberDetails />} roles={['admin']} />} />
              <Route path="/matches" element={<PrivateRoute element={<MatchesPage />} roles={['admin']} />} />
              <Route path="/matches/new" element={<PrivateRoute element={<MatchForm />} roles={['admin']} />} />
              <Route path="/matches/:id"  element={<PrivateRoute element={<MatchDetails />} roles={['admin']} />} />
              <Route path="matches/:id/add-members" element={<PrivateRoute element={<AddMembersToMatch />} roles={['admin']} />} />
              <Route path="/my-details" element={<PrivateRoute element={<UserDetails />} />} />
              <Route path="/add-child" element={<PrivateRoute element={<AddChild />} />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Layout>
        </AuthProvider>
      </Router>
      </ThemeProvider>
  );
};

export default App;


