import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { createChildMember, fetchMemberByEmail, fetchTeams } from '../api/api';

const AddChild = () => {
  const [childData, setChildData] = useState({
    name: '',
    dob: '',
    address_street: '',
    address_city: '',
    address_postcode: '',
    address_country: '',
    consent_for_photographs: false,
    team: '',
  });
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const getTeams = async () => {
      const teamsData = await fetchTeams();
      setTeams(teamsData);
    };

    getTeams();
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const parentEmail = location.state?.parentEmail;
  console.log(parentEmail);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setChildData({
      ...childData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const parent = await fetchMemberByEmail(parentEmail);
      await createChildMember(parent.id, childData);
      navigate('/');
    } catch (error) {
      console.error(error);
      // Handle error, e.g. display an error message to the user
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Add Child
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Child's Name"
              name="name"
              value={childData.name}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Date of Birth"
              name="dob"
              type="date"
              value={childData.dob}
              onChange={handleChange}
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address Street"
              name="address_street"
              value={childData.address_street}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address City"
              name="address_city"
              value={childData.address_city}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address Postcode"
              name="address_postcode"
              value={childData.address_postcode}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address Country"
              name="address_country"
              value={childData.address_country}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={childData.consent_for_photographs}
                  onChange={handleChange}
                  name="consent_for_photographs"
                />
              }
              label="Consent for Photographs"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
                <InputLabel id="team-label">Team</InputLabel>
                <Select
                labelId="team-label"
                id="team"
                name="team"
                value={childData.team}
                label="Team"
                onChange={handleChange}
                >
                {teams.map((team) => (
                    <MenuItem key={team.id} value={team.name}>
                    {team.name}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
          </Grid>
          {/* Add other form fields as necessary */}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Add Child
            </Button>
            <Button onClick={() => navigate('/')} variant="outlined" color="primary" style={{ marginLeft: '10px' }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddChild;